import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useNavigation } from "react-router-dom";
import { useSentry } from "../providers/sentry";

export function NotFound({children}: React.PropsWithChildren) {
  // If we got here from a returnTo (i.e. fromReturnTo is in state), then we want to go back to the homepage
  const navigate = useNavigate()
  const location = useLocation()
  const {captureEvent} = useSentry()
  useEffect(() => {
    if (location?.state?.fromReturnTo) {
      captureEvent({
        message: `Redirecting to homepage from returnTo`,
        level: 'warning',
        extra: {
          location
        }
      })
      navigate('/')
    }
  }, [navigate, location, captureEvent])
  

  return <div className="row">
    <div className="col">
      <h1>404 Not Found</h1>
      {children ||
        <p>We couldn't find what you're looking for.</p>}

      <Link to="/">Home</Link>
    </div>
  </div>
}
